<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/map.svg')"
      :title="`产品介绍`"
      :en_title="`Product Description`"
    ></Paragraph>
    <Titlenewlinelist :datalist="datalist1"></Titlenewlinelist>
    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`产品特点`"
      :en_title="`Features`"
    >
    </Paragraph>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistx.slice(0, 3)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      >
      </Lettertitleblock>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistx.slice(3, 5)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      >
      </Lettertitleblock>
    </div>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`架构设计`"
      :en_title="`Framework Design`"
    >
    </Paragraph>
    <div style="margin: 0 10%">
      <img
          :src="images[3]"
          style="width: 100%; height: 100%; align-items: center"
        />
    </div>

    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/note.svg')"
      :title="`可视化监控`"
      :en_title="`Visual Monitoring`"
    ></Paragraph>
    <Titlenewlinelist :datalist="datalist2"></Titlenewlinelist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlenewlinelist from '@/components/chain/basic/common/titlenewlinelist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    Paragraph,
    Titlenewlinelist,
    Lettertitleblock,
  },
  data() {
    return {
      title: '易居E·BaaS',
      body:
        '由易居（中国）控股有限公司旗下上海添玑网络服务有限公司自主研发。该平台旨在以不动产行业各细分业态为应用场景，'
        + '利用区块链数据防篡改、信息加密、授权交互及追踪溯源的特性，帮助不动产企业及专业人士轻松构建业务联盟。',
      // TODO: REPLACE WITH PRIVACY PANEL IMAGE
      image: require('@/assets/image/chain/baas/yiju/yiju.png'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/did/8_01.jpg'),
        require('@/assets/image/chain/va/did/8_02.jpg'),
        require('@/assets/image/chain/va/did/8_03.jpg'),
        require('@/assets/image/chain/baas/yiju/1.png'),
      ],
      datalist1: [
        {
          title: '> 区块链底层管理平台',
          content:
            '区块链底层管理平台（简称运维管理平台）是EBaaS为企业运维人员推出的区块链部署管理平台，提供专业、全面、可靠的区块链技术服务。',
        },
        {
          title: '> 运维管理平台',
          content:
            '运维管理平台提供多维度的可视化监控，第一时间掌握链运行状态和资源占用情况，方便运维与自动化部署，支持公有云和私有云部署，极大提高资源使用的灵活性并降低开发成本。',
        },
      ],
      datalistx: [
        {
          title: '高安全性',
          imgsrc: 'A',
          list: [
            '多级加密机制为数字安全、身份安全、',
            '通信安全、机构准入提供保障。',
          ],
        },
        {
          title: '高稳定性',
          imgsrc: 'B',
          list: [
            '基于超级账本fabric框架，智能合约引擎，为',
            '区块链应用提供高性能的商用价值。',
          ],
        },
        {
          title: '高隐私性',
          imgsrc: 'C',
          list: [
            '多级加密机制、合约访问控制、敏感信息',
            '加密上链等手段保护私密信息。',
          ],
        },
        {
          title: '高扩展性',
          imgsrc: 'D',
          list: [
            '模块化微服务架构、实现系统模块以及',
            '数据存储横向扩展，满足业务增长的扩容需求。',
          ],
        },
        {
          title: '简单易运维',
          imgsrc: 'E',
          list: [
            '多维度的可视化监控，第一时间掌握运',
            '行状态和资源占用情况，方便运维与系统管理。',
          ],
        },
      ],
      datalist2: [
        {
          title: '> 关键指标及快捷操作入口',
          content:
            '主机、联盟网路、通道、节点、智能合约等关键指标动态监控，概要数据一目了然，全面了解联盟链网络情况。快捷入口化繁为简，实现高效运维管理。',
        },
        {
          title: '> 联盟管理与组织信息',
          content:
            '通过网络拓扑图的形式呈现联盟和组织机构关系，用户可以直接查看创建或加入的联盟、组织、通道及成员信息。支持快速定位，拖拽组织及通道的位置，灵活节点增删，降低区块链使用门槛。',
        },
        {
          title: '> 通道成员与节点信息',
          content:
            '每个通道都是独立并且相互隔离的，以实现数据的保密。将复杂的部署结构和组织关系以网络拓扑图形式呈现，为企业及用户带来更直观的操作体验。',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
